<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId=='editWithDraw'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block;"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">Edit Withdraw Capital</h5>
            <a class="close" title="Close" @click="$store.dispatch('modalClose')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="submit"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-6 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Basic Information</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">ID</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              v-model="formData.capital_id"
                              class="form-control"
                              placeholder="id"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Date *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="date"
                              v-model="formData.date"
                              class="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Account Name *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <select class="form-control" v-model="formData.account_id" required @change="updateClosingBalance()">
                              <option value selected disabled>Account Head</option>
                              <option
                                v-for="(account,index) in capitalElements.accounts"
                                :key="index"
                                :value="account.id"
                              >{{account.account_name}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group">
                      <label class="group-label">Payment Type</label>
                      <div class="group-attribute-container">
                          <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Branch *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select v-model="formData.branch_id" :disabled="branchDisabled" class="form-control" required @change="getPayments">
                              <option value selected disabled>Select Branch</option>
                              <option
                                v-for="(method,index) in capitalElements.filter_branchs"
                                :key="index"
                                :value="method.id"
                              >{{method.name}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Payment Method *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select v-model="formData.payment_method_id" class="form-control" required @change="getBanks">
                              <option value selected disabled>Select Method</option>
                              <option
                                v-for="(method,index) in payement_methods"
                                :key="index"
                                :value="method.id"
                              >{{method.title}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Bank Name *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select v-model="formData.bank_id" class="form-control" required :disabled="bank_disabled">
                              <option value selected disabled>Select Bank</option>
                              <option
                                v-for="(bank,index) in banks"
                                :key="index"
                                :value="bank.id"
                              >{{bank.bank}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <!-- Transaction id is replaced with Ref. -->
                          <div class="col-md-5 mr-0 pr-0">Ref.</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="input"
                              v-model="formData.transaction_id"
                              placeholder="Payment Ref."
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 pd-l-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Amount Detail</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Total Capital Amt. *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="number"
                              step="any"
                              v-model="capital_closing_balance"
                              class="form-control"
                              placeholder="0.00"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Withdraw Amount *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="number"
                              v-model="formData.amount"
                              class="form-control"
                              placeholder="0.00"
                              min="1"
                              :max="capital_closing_balance"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="group" id="description">
                      <label class="group-label">Description</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-12">
                            <textarea
                              class="form-control"
                              v-model="formData.description"
                              style="height: 4.85rem;"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary" :disabled="disableSubmitButton">{{(disableSubmitButton)?"Submitting...":"Submit"}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Services from "./Services/Services";
export default {
  data() {
    return {
      details: {},
      formData: {
        capital_id: "",
        date: this.today,
        account_type_id:'',
        account_id: "",
        capital_type:1,
        payment_method_id: "",
        bank_id: '',
        branch_id:'',
        transaction_id: null,
        amount: null,
        description: null,
      },
      capital_closing_balance:'',
      disableSubmitButton:false,
      bank_disabled:true,
      payement_methods:[],
      banks:[],
      old_account:null,
      branchDisabled:false,
    };
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists1",
      "dataId",
      "modalId",
      "modalMode",
      "today",
    ]),
    ...mapGetters("capitals",["capitalLists","capitalElements"])
  },
  mounted() {
    this.formData.withdraw_date = this.today;
    this.formData.txn_id = Date.now();
  },
  methods: {
    submit() {
     this.disableSubmitButton=true;
       Services.editNewcapital(this.formData.id,this.formData)
        .then((res) => {
          this.$store.commit("capitals/setCapitalLists", res.data.data.data);
          this.$store.commit("setDataId", res.data.data.id);
          this.$store.dispatch("modalWithMode", {
            id: "addSlip",
            mode: "read",
          });
          console.log(res);
          this.$emit("parent-event");
        })
        .catch((err) => {
            console.log(err);
          if (err.response.status == 422) {
            let error = Object.values(err.response.data.errors).flat();
            this.setNotification(error);
          } else {
            let error = err.response.data.error_message;
            this.setNotification(error);
          }
        })
        .finally(() => {
          this.disableSubmitButton = false;
        });
    },
    updateClosingBalance(){
        let id=this.formData.account_id;
        let details =  this.capitalElements.accounts.find(function(data){
            return data.id == id;
        });
        if(this.old_account == id){
          this.capital_closing_balance = this.parseDigit(details.closing_balance)+this.parseDigit(this.formData.amount);
        }else{
          this.capital_closing_balance = this.parseDigit(details.closing_balance);
        }
    },
     getCapitalId(){
        Services.getCapitalId().then(res=>{
            this.formData.capital_id = res.data.data;
        }).catch(err=>{
            console.log(err);
        });
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
    getPayments() {
      this.bank_disabled = true;
      this.formData.bank_id = "";
      if (this.capitalElements.payment_methods.length != 0) {
        let details = [];
        let methods = this.formData.branch_id;
        details = this.capitalElements.payment_methods.filter(function (data) {
          return data.branch == methods;
        });
        this.payement_methods = { ...details };
        this.formData.payment_method_id = this.payement_methods[0].id;
      }
    },
    getBanks() {
      let payment_bank = this.formData.payment_method_id;
      let values = this.capitalElements.payment_methods.find(function (data) {
        if (data.id == payment_bank) {
          return data.title;
        }
      });
      if (values.title.toLowerCase() == "bank") {
        if (this.capitalElements.banks.length != 0) {
          this.bank_disabled = false;
          let details = [];
          let methods = this.formData.branch_id;
          details = this.capitalElements.banks.filter(function (data) {
            return data.branch == methods;
          });
          this.banks = { ...details };
          if (details.length > 0) {
            this.formData.bank_id = details[0].id;
          } else {
            this.formData.bank_id = "";
          }
        } else {
          this.bank_disabled = false;
          this.formData.bank_id = "";
        }
      } else {
        this.bank_disabled = true;
        this.formData.bank_id = "";
      }
    },
    clearFormData() {
      this.formData.id = "";
      this.formData.branch_id="";
      this.formData.date = this.today;
      this.formData.transaction_id = Date.now();
      this.formData.account_id = "";
      this.formData.payment_method_id = "";
      this.formData.bank_id = null;
      this.formData.amount = null;
      this.capital_closing_balance = null;
      this.formData.capital_type = 1;
      this.formData.description = null;
      this.branchDisabled=false;
    },
  },
  watch: {
    modalId(value) {
      if (value == "editWithDraw") {
        this.clearFormData();
        Services.getCapitalElements().then(res=>{
            this.$store.commit("capitals/setCapitalElements",res.data.data);
            let id = this.dataId;
            let details = this.capitalLists.find(function(data){
                return data.id == id;
            });
             if(res.data.data.filter_branchs.length == 1)
              {
                this.branchDisabled = true;
              }
            this.formData = {...details};
            this.formData.account_id = details.account_id;
            this.old_account = details.account_id;
            this.updateClosingBalance();
            this.getPayments();
            this.formData.payment_method_id = details.payment_method_id;
            this.getBanks();
            this.formData.bank_id = details.bank_id;
       }).catch(err=>{
           console.log(err);
       })
      }
    },
  },
};
</script>
